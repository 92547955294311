.container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  /* padding: 15px 0; */
  position: relative;
  margin-bottom: 10px;
}

.container div[class$="singleValue"] {
  font-size: 14px !important;
}

.title {
  /* opacity: 0.5; */
  font-size: 0.8em;
  color: #333;
  font-weight: 500;
}

.title::first-letter {
  text-transform: capitalize;
}
