.form {
  padding: 25px;
  display: block;
}

.link {
  display: block;
  width: 350px;
  opacity: 0.7;
  color: rgb(53 49 49);
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  letter-spacing: -0.38px;
  line-height: 25px;
  cursor: pointer;
  text-decoration: underline;
  margin: 15px 0;
}

@media screen and (max-width: 900px) {
  .form {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
    align-items: center;
  }

  .link {
    display: inline-block;
    text-align: center;
  }
}

@media screen and (max-width: 320px) {
  .link {
    display: inline-block;
    text-align: center;
    width: 280px;
  }
}
