.searchContainer {
  position: relative;
  border-radius: 4px;
  padding: 0 1px;
}

.withSearchTerm,
.searchContainer:hover,
.searchContainer:focus-within {
  box-shadow:
    -1.5px -1.5px 0 var(--color-border),
    1.5px -1.5px 0 var(--color-border),
    -1.5px 1.5px 0 var(--color-border),
    1.5px 1.5px 0 var(--color-border);
}

.searchIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #768d8c;
  cursor: pointer;
}

.searchInput {
  width: 0;
  border: none;
  outline: none;
  transition: width 0.4s ease-in-out;
  font-size: 14px;
  color: hsl(0deg 0% 20%);
  padding: 10px 15px;
  caret-color: transparent;
}

.searchInput:not(:placeholder-shown),
.searchContainer .searchInput:focus,
.searchContainer:hover .searchInput {
  width: 250px;
  caret-color: black;
}

.searchContainer.withSearchTerm .searchIcon,
.searchContainer:hover .searchIcon,
.searchContainer:focus-within .searchIcon {
  color: var(--color-princ-monibrand);
}
