.selectorContainer {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  flex-direction: column;
}

.selector {
  align-items: center;
  background-color: hsl(0deg 0% 100%);
  border-color: hsl(0deg 0% 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
}

.selector:hover {
  border-color: hsl(0deg 0% 70%);
}

.selectorLabel {
  font-size: 12px;
  text-transform: capitalize;
  color: #333;
  font-weight: 500;
}

.valueContainer {
  align-items: center;
  display: grid;
  flex: 1;
  flex-wrap: wrap;
  padding: 2px 8px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.placeholder {
  font-size: 14px !important;
  color: hsl(0deg 0% 50%);
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
}

.indicatorsContainer {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}

.indicatorSeparator {
  align-self: stretch;
  background-color: hsl(0deg 0% 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}

.indicatorContainer {
  color: hsl(0deg 0% 80%);
  display: flex;
  padding: 8px;
  transition: color 150ms;
  box-sizing: border-box;
}

.indicatorContainer:hover {
  color: hsl(0deg 0% 60%);
}

.indicatorSvg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}

.selectOptionSelected {
  border-color: #faba2e;
}
