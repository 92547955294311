.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0 20px;
}

.label {
  color: var(--color-bg-alt);
  width: 100%;
  cursor: pointer;
}

.label::first-letter {
  text-transform: uppercase;
}

.errorMsg {
  color: var(--color-txt-error);
  margin: 0 0 10px;
  padding: 0 15px;
  font-style: italic;
  font-size: 0.8em;
}

@keyframes error {
  from {
    border: 1px solid var(--color-txt-error);
    box-shadow: 0 0 5px var(--color-txt-error-light);
  }

  to {
    border: 1px solid var(--color-txt-error-light);
  }
}
