.container {
  display: flex;
  gap: 10px;
  padding: 5px;
  justify-content: center;
}

.container img {
  border-radius: 50%;
  overflow: hidden;
}
