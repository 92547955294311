.roundedStatus {
  border-radius: 50%;
  width: 21px;
  height: 21px;
  opacity: 1;
}

.statusNotWaiting {
  display: none;
}

.statusWaiting {
  display: inline-block;
  margin-left: 3px;
  color: var(--color-title);
  font-size: 10px;
}

.statusNew {
  background: var(--color-azure) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusReappearance {
  background: var(--color-purple) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusIgnored {
  background: var(--color-txt-error) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusAccepted {
  background: var(--color-green) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusRefused {
  background: var(--color-red) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusDisappeared {
  background: var(--color-dark-green) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusRequested {
  background: var(--color-dark-blue) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusInprogress {
  background: var(--color-princ-monibrand) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusReminded {
  background: var(--color-orange) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusBlocked {
  background: var(--color-bg-alt) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.timeline {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
}

.labels {
  margin-left: 15px;
  font-size: 14px;
}

.status {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.timeline::after {
  content: "";
  position: absolute;
  right: 9px;
  left: 10px;
  top: 26px;
  height: calc(100% - 21px);
  background: var(--color-timeline);
  border-radius: 3px;
  z-index: 1;
}

.beforeReappearance .timeline::after {
  background:
    repeating-linear-gradient(
      to bottom,
      var(--color-timeline) 0,
      var(--color-timeline) 3px,
      #fff 3px,
      #fff 6px
    );
}

@media only screen and (max-width: 1240px) {
  .timeline::after {
    bottom: 12px;
  }
}

.status.last .timeline::after {
  width: 0;
}

.statusLabel {
  font-weight: 500;
  color: var(--color-title);
}

.statusDate {
  color: var(--color-title);
}
