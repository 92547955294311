.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-shadow);
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.modale {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 15px;
  box-shadow: 5px 5px 5px var(--color-shadow);
  font-size: 1.2rem;
  border-radius: 10px;
  background-color: #fff;
  z-index: 200;
  overflow-y: auto;
}

.modaleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.modaleHeaderTitle {
  margin-right: 10px;
  text-align: center;
  color: black;
  font-size: 18px;
  font-weight: 500;
}

.modaleHeaderTitle::first-letter {
  text-transform: uppercase;
}

.close {
  width: 18px;
  height: 18px;
}

.modale[data-height="true"] {
  height: calc(100vh - 75px);
}
