.container {
  position: relative;
  height: 100%;
}

.box {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
