.container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 10px;
  padding-left: 40px;
  padding-right: 20px;
  background-color: var(--color-bg-extra-light);
}

.title {
  font-size: 24px;
  font-weight: 500;
}

.tagList {
  width: 100%;
}
