.container {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 5px;

  --with: attr(data-percent);

  align-items: center;
}

.value {
  white-space: nowrap;
  font-size: 0.9em;
}

.bar {
  height: 5px;
  position: relative;
  background: var(--color-bg-lightgrey);
  width: 100%;
  min-width: 50px;
}

.progress {
  content: attr(data-percent);
  position: absolute;
  background: var(--color-princ-monibrand);
  top: 0;
  bottom: 0;
  left: 0;
}
