.container {
  display: flex;
  gap: 10px;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.txt::first-letter {
  text-transform: uppercase;
}

.txt {
  text-align: center;
}
