.container {
  width: 548px;
  height: 100%;
}

.header {
  color: var(--color-title);
  font-size: 20px;
  font-weight: 500;
  margin-left: 20px;
  margin-bottom: 20px;
}

.history {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;
}
