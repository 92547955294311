.container {
  flex-direction: column;
}

.containerButtons {
  justify-content: space-between;
}

.container,
.containerButtons {
  display: flex;
  gap: 10px;
}
