.container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.table {
  border-collapse: collapse;
  box-sizing: border-box;
  margin: 0 0 10px;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.contentTable {
  min-height: unset !important;
}

.tableContainer {
  flex: 1 1 100%;
}

.td {
  padding: 5px 10px;
  text-align: left;
}

.td.alignRight {
  text-align: right;
}

.td.alignCenter {
  text-align: center;
}

.td.alignLeft {
  text-align: left;
}

.tr.clickOnRow {
  cursor: pointer;
}

.tr:nth-child(even) {
  background: rgb(0 0 0 / 3%);
}

.tr:hover {
  background-color: var(--color-hover);
}

.tr .rowTrigger {
  visibility: hidden;
  position: relative;
}

.tr .rowTrigger svg {
  position: absolute;
  transform: translate(0, -50%);
  cursor: pointer;
}

.tr:hover .rowTrigger {
  visibility: visible;
}

.tr.warning {
  background-color: rgba(255 213 128 / 20%);
}

.isRowChecked {
  background-color: var(--color-hover) !important;
}

.noResult {
  background: white;
  color: #333;
  font-size: 2em;
  padding: 15px;
}

.noResult img {
  width: 250px;
  margin-top: 20px;
}

.noResultTitle {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: var(--color-title);
  margin-top: 20px;
  margin-bottom: 8px;
}

.noResultText {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-title);
}
