:root {
  /* jaune Monibrand */
  --color-princ-monibrand: #faba22;
  --color-princ-monibrand-hover: #daa21e;
  --color-princ-monibrand-second: #333;
  --color-txt-error: #960b0b;
  --color-txt-error-light: rgb(202 12 12);
  --color-txt-princ: #000;
  --color-bg-alt: #737a81;
  --color-bg-lightgrey: #ddd;
  --color-bg-tab: #fafafa;
  --color-bg-extra-light: #f6f6f6;
  --color-link-menu: #768d8c;
  --color-azure: #65bcbb;
  --color-red: #e51400;
  --color-red-border: #ca1007;
  --color-purple: #531edc;
  --color-orange: #ff8000;
  --color-green: #129519;
  --color-dark-green: #035607;
  --color-dark-blue: #0a009b;
  --color-yellow-border: #e2a20e;
  --color-border: #dedede;
  --color-title: #333;
  --color-date: #555;
  --color-grey: #777;
  --color-border-alt: #d9d9d9;
  --color-shadow: rgb(0 0 0 / 30%);
  --color-th: rgb(0 0 0 / 50%);
  --color-input-border: #dedede;
  --color-hover: #e6e6e6;
  --color-timeline: #dcdcdc;
  --color-loading: #c7c7c7;

  /* GOOGLE */
  --color-google-link: #1a0dab;
  --color-google-desc: #4d5156;
  --color-google-top-link: #202124;
  --color-google-price: #323335;
}
