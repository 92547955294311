.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 66px);
}

.center {
  align-items: center;
  justify-content: center;
}

.body {
  background-color: var(--color-bg-extra-light);
  padding-bottom: 20px;
}
