.container {
  padding: 0 20px !important;
  background-color: var(--color-bg-extra-light);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  padding-bottom: 20px !important;
}

.noShoppingAdvertisement {
  grid-template-columns: repeat(1, 1fr);
}

.advertisementContainer {
  padding: 0 15px;
  padding-top: 15px;
  margin-bottom: 20px;
}

.classicalAdvertisement {
  background-color: var(--color-azure) !important;
}

.classicalAdvertisementHeader {
  color: #fff !important;
}

.classicalAdvertisementOpenToggle {
  color: #fff !important;
}

.shoppingAdvertisement {
  color: #fff !important;
  background-color: var(--color-bg-alt) !important;
}

.shoppingAdvertisementHeader {
  color: #fff !important;
}

.shoppingAdvertisementOpenToggle {
  color: #fff !important;
}

.loader {
  display: flex;
}

.loaderParenthesisStart {
  margin-left: 15px;
  margin-right: 10px;
}

.loaderParenthesisEnd {
  margin-left: 10px;
}
