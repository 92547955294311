.container {
  display: grid;
  grid-template-columns: auto 1fr auto;
  margin: 10px 0;
}

.container > div {
  padding: 10px;
}

.container > div:not(.container > div:nth-child(1n +4)) {
  background: var(--color-bg-extra-light);
}

.counters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.panel {
  position: absolute;
}

.description {
  display: block;
  grid-column: 1/4;
}

@media screen and (max-width: 700px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }
}
