.container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 16px;
  text-align: left;
}

.input {
  font-family: "Work Sans", sans-serif;
  border: 1px solid #ccc;
  background: transparent 0% 0% no-repeat padding-box;
  padding: 9px;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
  resize: none;
  outline: none;
}
