.container {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: normal;
  color: var(--color-title);
  align-items: center;
  align-content: center;
  white-space: nowrap;
  user-select: none;
  position: relative;
}

.container.noCount {
  padding: 0;
}

.countHint {
  position: absolute;
  top: 2px;
  right: 4px;
  border-radius: 50%;
  background-color: var(--color-red);
  color: #fff;
  padding: 3px;
  font-size: 12px;
  width: 18px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border: solid 1px #fff;
}

.countHintThree {
  width: unset;
  border-radius: 20px;
  right: -3px;
}

.countHintFour {
  width: unset;
  border-radius: 20px;
  right: -10px;
}
