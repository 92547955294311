.container {
  flex-direction: column;
  gap: 5px;
  min-width: 250px;
}

.buttons,
.container {
  justify-content: center;
}

.buttons,
.container,
.list,
.listItems,
.resume {
  display: flex;
}

.buttons {
  gap: 50px;
  align-items: center;
  margin: 10px 0 0;
}

.resume {
  margin-top: 15px;
  flex-direction: column;
  gap: 10px;
}

.titleResume::first-letter {
  text-transform: uppercase;
}

.titleResume {
  font-weight: 700;
  border-bottom: 1px solid var(--color-princ-monibrand);
}

.listItem {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  align-items: center;
}

.titleList {
  font-weight: 700;
  color: var(--color-princ-monibrand-second);
}

.titleList::first-letter {
  text-transform: uppercase;
}

.listItems {
  flex-wrap: wrap;
  gap: 10px;
  padding: 3px;
  color: #fff;
}

.delete {
  background: var(--color-txt-error);
}

.add {
  background: var(--color-green);
}

.add,
.delete {
  padding: 3px;
  border-radius: 3px;
  font-size: 0.8em;
}

.list {
  flex-direction: column;
  gap: 15px;
}
