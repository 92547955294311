.title {
  align-items: center;
  color: var(--color-link-menu);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 1.1em;
  gap: 5px;
  padding: 5px 0;
}

.title[data-style="big"] {
  color: var(--color-title);
  font-size: 1.5em;
}

.titleTxt::first-letter {
  text-transform: uppercase;
}

.icon,
.title {
  transition: 0.3s ease-in-out;
}

.icon {
  margin-left: 5px;
}

.icon[data-down="true"] {
  transform: rotate(0.5turn);
}

.panel {
  margin: 10px;
}
