.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.add,
.filterName {
  background: #fff;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 15px;
  transition: 0.2s ease-in-out;
  color: var(--color-title);
  border: 1px solid var(--color-border);
}

.addTxt {
  white-space: nowrap;
}

.addTxt::first-letter,
.filterName::first-letter {
  text-transform: uppercase;
}

.filter[data-save="true"] {
  border: 1px solid var(--color-border);
}

.filterName {
  position: relative;
}

.add {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 5px;
  align-items: center;
}

.add:hover,
.filterName:hover {
  background: var(--color-princ-monibrand);
}

.filterName[data-save="true"] .filterName::after {
  content: "*";
  top: -3px;
  font-size: 15px;
  opacity: 0.5;
  right: 5px;
}

.filter {
  position: relative;
}

.filterName[data-current="true"] {
  background: var(--color-bg-lightgrey);
}

.filterName[data-hover="true"] {
  background: var(--color-princ-monibrand);
  color: white;
}

.child {
  position: absolute;
  top: calc(100% + 20px);
  left: 0;
  z-index: 100;
  background: #fff;
  padding: 10px;
  border: 1px solid var(--color-border);
  min-width: 300px;
  border-radius: 5px;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 50%);
}

.labelFilter {
  display: flex;
  transition: 200ms ease-in-out;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.1em;
  font-weight: 500;
  color: var(--color-title);
}

.labelFilter span::first-letter {
  text-transform: uppercase;
  align-self: right;
}

@media screen and (max-width: 700px) {
  .labelFilter {
    display: none;
  }
}
