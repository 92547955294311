.wrapper p {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 12px;
  color: rgb(192 192 192);
}

.wrapper .passed {
  color: green;
}

.wrapper .passed::before {
  content: "✔";
  display: inline-block;
  margin-right: 0.4em;
}

.wrapper .notpassed {
  color: rgb(192 192 192);
}

.wrapper .notpassed::before {
  content: "✗";
  display: inline-block;
  margin-right: 0.4em;
}
