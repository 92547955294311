.container {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  flex: 1 1 auto;
}

.body {
  flex-grow: 1;
}

.content {
  display: flex;
  scroll-behavior: "smooth";
}

.footer {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  color: var(--color-link-menu);
}

.input {
  outline: none;
  font-family: "Work Sans", sans-serif;
  height: 36px;
  border: none;
  background: var(--color-bg-extra-light) 0% 0% no-repeat padding-box;
  padding: 9px;
  font-size: 14px;
  border-radius: 5px;
  width: calc(100% - 32px);
  margin-right: 10px;
  resize: none;
}

.input:hover {
  background-color: var(--color-bg-lightgrey);
  box-shadow:
    -1.5px -1.5px 0 var(--color-border),
    1.5px -1.5px 0 var(--color-border),
    -1.5px 1.5px 0 var(--color-border),
    1.5px 1.5px 0 var(--color-border);
}

.input:focus {
  height: 150px;
  background-color: white;
  border: solid var(--color-princ-monibrand) 1px;
  box-shadow: inherit;
}

.empty {
  color: var(--color-bg-alt);
}

.submitButton {
  position: relative;
  bottom: 0;
  padding: 0;
  background-color: transparent;
}

.submitButton:hover {
  background-color: transparent;
}

.submitButton:not([disabled]):focus path,
.submitButton:not([disabled]):hover path,
.submitButton:not([disabled]):active path {
  fill: var(--color-princ-monibrand);
}

.submitIcon {
  font-size: 32px;
  color: var(--color-link-menu);
}
