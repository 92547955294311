.container {
  flex-direction: column;
  gap: 5px;
  display: flex;
  position: relative;
}

.container .container button:hover > span:nth-child(2) {
  color: #000 !important;
}

@media screen and (max-width: 600px) {
  .container {
    width: 100% !important;
  }
}
