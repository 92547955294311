.button {
  padding: 5px;
  border-radius: 3px;
  border: 1px solid var(--color-bg-lightgrey);
  cursor: pointer;
  transition: 0.2s ease-in-out;
  background: white;
  color: var(--color-bg-alt);
  text-transform: lowercase;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
}

.button::first-letter {
  text-transform: uppercase;
}

.button:hover {
  box-shadow: 3px 3px 5px rgb(0 0 0 / 30%);
}
