.modale {
  background-color: #fff;
  display: flex;
  gap: 10px;
  flex-direction: column;
  white-space: nowrap;
  font-size: 1.2rem;
  width: 520px;
}

.twoFieldsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftField {
  width: 100%;
  padding-right: 10px;
}

.rightField {
  width: 100%;
  padding-left: 10px;
}

.containerButtons {
  margin-top: 43px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerButtons button {
  margin: 0 10px;
}
