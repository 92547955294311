.container {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: var(--color-princ-monibrand);
}

.item {
  display: flex;
  flex-direction: column;
  margin: 5px;
  font-size: 0.8em;
  align-items: flex-end;
}

.logo {
  height: 45px;
  width: auto;
  display: block;
  text-decoration: none;
}

.logo.collapsed {
  width: 28px;
}

.rightPanel {
  display: flex;
  align-items: center;
}
