.containerButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.containerButtons,
.select {
  grid-column: 1/3;
}

.form {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
}

.input {
  cursor: pointer;
}

.fullSizeField {
  grid-column: 1/3;
}

.field,
.fieldAutoMatch {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 5px;
  border: 1px solid var(--color-bg-alt);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.field label {
  cursor: pointer;
}

.field:hover {
  box-shadow: 2px 2px 5px var(--color-shadow);
}

.fieldAutoMatch {
  align-self: end;
}

.select {
  border: 1px solid var(--color-bg-alt);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.labelSelect {
  color: var(--color-bg-alt);
  width: 100%;
  cursor: pointer;
  padding-bottom: 10px;
}

@media screen and (max-width: 700px) {
  .form {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
}
