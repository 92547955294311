.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--color-princ-monibrand);
}

.containerGrid {
  position: relative;
  top: -25px;
  right: -25px;
  left: -25px;
  bottom: 21px;
  border-radius: 10px;
  background: white;
}

.grid {
  position: relative;
  min-height: 40vh;
  max-width: 800px;
  padding: 0 20px;
  display: grid;
  border-radius: 25px;
  grid-template-columns: 1fr 0.8fr;
}

.containerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 21px;
}

.title {
  font-size: 3em;
  font-weight: 500;
  color: rgb(53 49 49);
  text-align: center;
  grid-column: 1/3;
  margin: 10px 10px 50px;
}

.title::first-letter {
  text-transform: uppercase;
}

.containerImg {
  position: relative;
}

.img {
  transform: translateY(22px);
  width: 100%;
}

@media screen and (max-width: 900px) {
  .container {
    padding: 10px;
  }

  .grid {
    margin: 0 auto;
    grid-template-columns: 1fr;
  }

  .title {
    font-size: 1.5em;
    font-weight: 500;
    color: rgb(53 49 49);
    text-align: center;
    grid-column: 1/3;
    margin: 10px;
  }

  .containerForm {
    padding-bottom: 50px;
  }

  .containerImg {
    grid-row: 3/4;
  }

  .containerImg img {
    display: none;
  }

  .containerGrid::before {
    right: 0;
    left: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .containerGrid {
    top: -25px;
    right: -25px;
    left: -25px;
    bottom: 21px;
    border-radius: 10px;
  }

  form {
    width: 70%;
  }
}

@media screen and (max-width: 425px) {
  .containerGrid {
    top: -25px;
    right: -25px;
    left: -25px;
    bottom: 21px;
    border-radius: 10px;
    margin-left: 50px;
  }

  form {
    width: 70%;
  }
}

@media screen and (max-width: 375px) {
  .containerGrid {
    width: 320px;
    top: -25px;
    right: -25px;
    left: -25px;
    bottom: 21px;
    border-radius: 10px;
    margin-left: 50px;
  }

  .containerForm {
    width: 320px;
  }

  form {
    width: 70%;
  }

  .title {
    width: 320px;
  }
}

@media screen and (max-width: 320px) {
  .containerGrid {
    width: 300px;
    top: -25px;
    right: -25px;
    left: -25px;
    bottom: 21px;
    border-radius: 10px;
    margin-left: 50px;
  }

  .containerForm {
    width: 280px;
  }

  .title {
    width: 280px;
  }

  form {
    width: 70%;
  }
}
