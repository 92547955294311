.container {
  display: flex;
  gap: 70px;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.inputGroup {
  display: flex;
  align-items: center;
}

@keyframes error {
  from {
    border: 1px solid var(--color-txt-error);
    box-shadow: 0 0 5px var(--color-txt-error-light);
  }

  to {
    border: 1px solid var(--color-txt-error-light);
  }
}
