.container {
  margin: 0;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 5px;
  border-radius: 4px;
  color: hsl(0deg 0% 20%);
  padding: 8px;
  box-shadow:
    -1px -1px 0 var(--color-input-border),
    1px -1px 0 var(--color-input-border),
    -1px 1px 0 var(--color-input-border),
    1px 1px 0 var(--color-input-border);
}

.container:hover {
  box-shadow:
    -1.5px -1.5px 0 var(--color-border),
    1.5px -1.5px 0 var(--color-border),
    -1.5px 1.5px 0 var(--color-border),
    1.5px 1.5px 0 var(--color-border);
}

.container[data-disabled="true"] {
  opacity: 0;
}

.input {
  position: relative;
  outline: none;
  border: none;
  font-size: 14px;
  color: hsl(0deg 0% 20%);
}

.percent {
  opacity: 0.5;
}
