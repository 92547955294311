.modale {
  background-color: #fff;
  display: flex;
  gap: 10px;
  flex-direction: column;
  white-space: nowrap;
  font-size: 1.2rem;
}

.modale.large {
  width: 800px;
}

.headerImage {
  text-align: center;
}

.title {
  margin-top: 32px;
  margin-bottom: 10px;
  text-align: center;
  color: black;
  font-size: 25px;
}

.input {
  outline: none;
  font-family: "Work Sans", sans-serif;
  height: 150px;
  border: 1px solid #ccc;
  background: transparent 0% 0% no-repeat padding-box;
  padding: 9px;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 20px;
  margin-right: 120px;
  margin-left: 120px;
  resize: none;
}

.subTitle {
  text-align: center;
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--color-link-menu);
}

.title::first-letter {
  text-transform: uppercase;
}

.containerButtons {
  margin-top: 13px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerButtons button {
  margin: 0 10px;
}
