.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.labelWithIcon {
  display: flex;
  gap: 5px;
  align-items: center;
}

.labelWithIcon .icon {
  display: flex;
  align-items: center;
  height: 100%;
}

.counterText {
  font-size: 14px;
}
