.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px 0;
  transform: scale(2);
}

.cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  font-weight: bold;
  height: 100%;
}

.txt {
  grid-column: 1 / 4;
  text-align: center;
  font-size: 0.6em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  display: block;
  color: white;
  text-decoration: none;
}
