.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  color: var(--color-bg-alt);
  width: 100%;
  cursor: pointer;
}

.label::first-letter {
  text-transform: uppercase;
}

.input {
  outline: none;
  border: 1px solid #dedede;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}

.input:hover {
  box-shadow:
    -1.5px -1.5px 0 var(--color-border),
    1.5px -1.5px 0 var(--color-border),
    -1.5px 1.5px 0 var(--color-border),
    1.5px 1.5px 0 var(--color-border);
}

.input[data-error="true"] {
  border: 1px solid var(--color-txt-error-light);
  animation: error 500ms 3;
}

.errorMsg {
  color: var(--color-txt-error);
  margin: 0 0 10px;
  padding: 0 15px;
  font-style: italic;
  font-size: 0.8em;
}

@keyframes error {
  from {
    border: 1px solid var(--color-txt-error);
    box-shadow: 0 0 5px var(--color-txt-error-light);
  }

  to {
    border: 1px solid var(--color-txt-error-light);
  }
}
