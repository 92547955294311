.container {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  gap: 5px;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

.round {
  --status-size: 6px;

  width: var(--status-size);
  height: var(--status-size);
  border-radius: 50%;
  background: var(--color-bg-lightgrey);
}

.round[data-color="archived"] {
  background: var(--color-txt-error);
}

.round[data-color="disabled"] {
  background: var(--color-red);
}

.round[data-color="enabled"] {
  background: var(--color-green);
}
