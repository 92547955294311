.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grid {
  display: inline-grid;
  margin: 8px auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 5px;
}

.point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0;
  animation: appear 3s infinite backwards;
}

.point[data-color="black"] {
  background: #000;
}

.point[data-color="white"] {
  background: #fff;
}

.point[data-color="monibrand"] {
  background: var(--color-princ-monibrand);
}

@keyframes appear {
  50%,
  70% {
    opacity: 1;
  }

  0%,
  100%,
  80% {
    opacity: 0;
  }

  100%,
  50% {
    transform: translateX(0);
  }
}
