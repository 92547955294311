.footer {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color-link-menu);
  width: 100%;
  transition: 0.2s ease-out;
  overflow: hidden;
}

.footer.collapsed {
  justify-content: center;
}

.footerText {
  margin-left: 15px;
}

.footer:hover .footerText {
  color: var(--color-princ-monibrand);
}

.footer:hover path {
  fill: var(--color-princ-monibrand);
}
