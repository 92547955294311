.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
}

.actionIcon {
  height: 18px;
  color: var(--color-link-menu);
}
