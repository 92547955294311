.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 66px);
}

.center {
  align-items: center;
  justify-content: center;
}

.body {
  background-color: var(--color-bg-extra-light);
  padding-bottom: 20px;
}

.iframe {
  border: 0;
  padding: 20px;
  box-shadow: 0 2px 4px #00000029;
  border-radius: 5px;
  background-color: #fff;
  height: calc(100vh + 40px);
  width: 100%;
  overflow: hidden !important;
  top: 0;
}

.iframeContainer {
  display: flex;
  padding: 20px;
  background-color: transparent;
  width: 100%;
}
