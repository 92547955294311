.container {
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  width: 800px;
  max-height: 500px;
  grid-gap: 30px;
  gap: 30px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.frequencyContainer {
  width: 75%;
  text-align: left;
}

.searchConvertRateContainer {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.searchConvertRateContainer :nth-child() {
  width: 100%;
}

.searchConvertRateContainer[data-disabled="true"] {
  opacity: 0;
}

.icon {
  color: var(--color-princ-monibrand);
  opacity: 1;
}

.infoText {
  font-size: 0.7em;
  display: flex;
  gap: 10px;
  align-items: center;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding-bottom: 40px;
}
