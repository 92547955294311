.workspacesArea {
  margin-right: 15px;
  display: flex;
  flex-direction: row;
}

.workspaceButton {
  padding: 9px 10px;
  margin-right: 3px;
}

.workspaceActive .workspaceButton {
  background: #fff;
}

.workspaceActive .workspaceButton path {
  fill: var(--color-princ-monibrand);
}
