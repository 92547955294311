.container {
  position: relative;
  display: inline-block;
}

.modale {
  position: fixed;
  border-radius: 10px;
  background: #fff;
  box-shadow: 3px 3px 10px var(--color-shadow);
  font-size: 1em;
  box-sizing: border-box;
  z-index: 5;
  display: none;
  color: var(--color-link);
  overflow: hidden;
  padding: 13px 0;
}

.menu {
  width: 100%;
  cursor: pointer;
  padding: 0 5px;
}

tr:last-child .modale {
  bottom: 35px;
  top: initial;
}

.icon {
  transition: 0.2s ease-in-out;
  color: var(--color-link-menu);
  justify-self: center;
}

.icon:hover,
.iconOpen {
  color: var(--color-princ-monibrand);
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
