.container {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  white-space: nowrap;
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 10px;
  text-align: left;
  align-items: center;
  padding: 7px 20px;
  min-width: 320px;
}

.container:hover {
  background: var(--color-bg-extra-light);
}

.container[data-isactive="false"] {
  opacity: 0.5;
}

.text::first-letter {
  text-transform: uppercase;
}

.picto {
  color: var(--color-link-menu);
  display: flex;
  align-items: center;
}
