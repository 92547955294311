.container {
  background-color: white;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  white-space: nowrap;
  border: 1px solid var(--color-bg-alt);
  border-radius: 3px;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 20%);
  font-size: 1rem;
  z-index: 103;
  cursor: pointer;
  margin: 0 5px;
  animation: appear 500ms;
}

.grid {
  display: grid;
  gap: 15px;
  padding: 10px;
}

.cross {
  display: flex;
  flex-direction: row-reverse;
}

@keyframes appear {
  from {
    clip-path: circle(1.4% at 50% 50%);
  }

  to {
    clip-path: circle(100% at 50% 50%);
  }
}
