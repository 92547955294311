.action {
  padding: 5px;
  border-radius: 3px;
  border: 1px solid var(--color-bg-lightgrey);
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.action:hover {
  box-shadow: 3px 3px 5px rgb(0 0 0 / 30%);
}

.containerModale {
  width: 700px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.titleModale {
  text-align: center;
  color: black;
  font-weight: 500;
  font-size: 1.2em;
  margin: 0;
}

.titleModale::first-letter {
  text-transform: uppercase;
}

@media screen and (max-width: 800px) {
  .containerModale {
    width: 100%;
  }
}
