.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.label {
  opacity: 0.5;
  margin-bottom: 5px;
}
