.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding: 5px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-border);
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
}

.chip {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  background: var(--color-link-menu);
  padding: 5px 7px;
  border-radius: 5px;
  font-size: 0.8rem;
}

.close {
  color: white;
  padding-top: 3px;
  font-size: 0.8em;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  font-size: 0.8rem;
}

.input {
  border: none;
  outline: 0;
  font-size: 16px;
  color: var(--color-date);
  width: 100%;
  padding: 0 5px;
  overflow: hidden;
  resize: none;
  font-family: "Work Sans", sans-serif;
}
