.container {
  font-size: 14px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-content: space-between;
  transition: 0.2s ease-out;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 48px;
  box-shadow: 0 8px 10px #00000029;
  z-index: 200;
  background-color: #fff;
}

.expanded {
  width: 210px;
}

.container.hovered {
  position: absolute;
  left: 0;
  top: 0;
  width: 210px;
}

.container.minized {
  position: absolute;
  left: 0;
  top: 0;
}

/* @media screen and (max-width: 1450px) {
  .container {
    min-height: inherit;
    width: auto;
    font-size: 0.8em;
    max-width: 120px;
  }
}

@media screen and (max-width: 770px) {
  .container {
    min-height: inherit;
    width: inherit;
  }
} */
