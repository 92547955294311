.container {
  box-sizing: border-box;
  height: 50px;
  border: 0.5px solid var(--color-link-menu);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
  margin: 5px;
}

.error {
  border: 0.5px solid var(--color-txt-error);
}

.label {
  margin: 10px 15px 0;
  height: 11px;
  color: #768d8c;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.33px;
  line-height: 11px;
  text-transform: uppercase;
  width: 100%;
}

.input {
  border: none;
  outline: 0;
  margin-left: 15px;
  width: calc(100% - 30px);
}

@media screen and (max-width: 900px) {
  .container {
    width: 100%;
  }
}
