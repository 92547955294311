.container {
  background: var(--color-bg-extra-light);
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 10px;
}

.close {
  display: flex;
  flex-direction: row-reverse;
  padding: 10px;
}

.cross {
  font-size: 2em;
  color: var(--color-princ-monibrand-second);
  cursor: pointer;
}
