.container {
  margin-bottom: 10px;
}

.count {
  text-align: center;
  opacity: 0.5;
  font-size: 0.8em;
}

.title {
  /* opacity: 0.5; */
  font-size: 12px;
  color: #333;
  font-weight: 500;
}

.title::first-letter {
  text-transform: capitalize;
}
