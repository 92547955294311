.container {
  position: relative;
}

.container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--color-border);
}

.container:last-child::after {
  background: none;
}

.containerAdvertisement {
  gap: 10px;
}

.advertisement {
  flex-direction: column;
  gap: 5px;
}

.resume {
  flex-direction: column;
  gap: 5px;
  text-align: left;
}

.td {
  padding: 8px;
  vertical-align: top;
}

.tdResume {
  width: 40%;
}

.img {
  width: 100%;
  max-width: 150px;
  height: auto;
  justify-content: center;
}

.img img {
  width: 100%;
  height: auto;
}

.topLink {
  color: var(--color-google-top-link);
}

.title {
  color: var(--color-google-link);
  font-size: 1.1em;
  gap: 5px;
}

.desc {
  color: var(--color-google-desc);
}

.info {
  flex-direction: column;
  gap: 5px;
}

.infoLeft {
  align-items: flex-start !important;
}

.infoTitle {
  color: var(--color-bg-alt);

  /* white-space: nowrap; */
  text-align: left;
  font-size: 12px;
}

.keywords {
  padding: 0;
  margin: 0;
  color: var(--color-title);
  list-style-type: none;
  gap: 5px;
  flex-direction: column;
}

.num {
  color: #fff;
  background: var(--color-azure);
  justify-content: center;
  justify-items: center;
  text-align: center;
  border-radius: 5px;
  padding: 2px 5px;
}

.views {
  flex-direction: column;
  gap: 10px;
}

.view {
  gap: 10px;
  justify-content: flex-start;
}

.price {
  font-weight: 700;
  color: var(--color-google-top-link);
  letter-spacing: 0.2px;
  margin: 0;
}

.plus {
  cursor: pointer;
  border-bottom: 1px solid var(--color-border);
  padding: 3px;
  font-size: 0.8em;
  word-break: keep-all;
}

.date,
.keyword {
  white-space: nowrap;
}

.keyword {
  border: 1px solid var(--color-border);
  padding: 3px 6px;
  border-radius: 5px;
  font-size: 12px;
}

.label {
  color: var(--color-title);
  font-size: 14px;
}

.cols {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.engine {
  transform: scale(1.5);
  color: var(--color-title);
  height: 35px;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.advertisement,
.containerAdvertisement,
.img,
.info,
.num,
.resume,
.keywords,
.title,
.view,
.views {
  display: flex;
}

.img,
.info,
.num,
.title,
.view {
  align-items: flex-start;
}

.cols > .info {
  align-items: center;
}

.link {
  word-break: break-all;
}

.small {
  font-size: 12px;
}

.intentSuccess {
  background: var(--color-green);
}

.labelAction,
.labelActionCopy {
  height: 35px;
  margin-top: 0;
  font-size: 20px;
  text-align: left;
  margin-left: 2px;
}

.labelAction div {
  margin-top: 10px;
}

.labelActionCopy div {
  margin-top: 7px;
}

.labelAction path,
.labelActionCopy path {
  fill: var(--color-link-menu);
}

.labelAction:hover path,
.labelActionCopy:hover path {
  fill: var(--color-princ-monibrand);
}
