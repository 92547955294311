.actionPanel {
  margin: 15px;
  box-shadow: 0 2px 10px #00000016;
}

.actionPanel:hover {
  box-shadow: 0 2px 10px rgb(0 0 0 / 40%);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transform: translateY(-5px);
}

.actionHeader {
  background-color: var(--color-bg-extra-light);
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.actionHeaderName {
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 500;
}

.externalLink {
  color: var(--color-link-menu);
}

.externalLink:hover {
  color: var(--color-princ-monibrand);
}

.actionHeaderTitle {
  cursor: pointer;
}

.actionHeaderTitleActions {
  display: none;
  justify-content: center;
}

.actionHeaderTitleText,
.dateText {
  font-size: 18px;
  font-weight: 500;
}

.actionHeaderTitle:hover .actionHeaderTitleText {
  visibility: hidden;
  height: 0;
}

.actionHeaderTitle:hover .actionHeaderTitleActions {
  display: flex;
}

.actionHeaderTitleAction {
  margin: 0 8px;
  cursor: pointer;
}

.actionHeaderTitleAction svg {
  cursor: pointer;
}

.actionHeaderTitleAction:hover path {
  fill: var(--color-princ-monibrand);
}

.actionHeaderDate {
  display: flex;
  justify-content: center;
}

.labelWithIcon {
  display: flex;
  gap: 5px;
  align-items: center;
}

.labelWithIcon .icon {
  display: flex;
  align-items: center;
  height: 100%;
}

.actionHeaderHints {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
}

.actionHeaderHints .labelWithIcon {
  margin-left: 10px;
}

.actionFlagIcon {
  width: 26.6px !important;
  line-height: unset !important;
  height: 20px !important;
}

.actionEngineIcon {
  width: 22px;
  height: 22px;
}

.counterText {
  font-size: 20px;
}

.actionBody {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 30px;
  position: relative;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}

.roundedStatus {
  border-radius: 50%;
  width: 21px;
  height: 21px;
  opacity: 1;
}

.statusNew {
  background: var(--color-azure) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusReappearance {
  background: var(--color-purple) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusIgnored {
  background: var(--color-txt-error) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusAccepted {
  background: var(--color-green) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusRefused {
  background: var(--color-red) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusDisappeared {
  background: #035607 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusRequested {
  background: #0a009b 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusInprogress {
  background: var(--color-princ-monibrand) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusReminded {
  background: var(--color-orange) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.statusBlocked {
  background: var(--color-bg-alt) 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 3px #00000029;
  border: 4px solid #fff;
}

.timeline {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

.status.responsive {
  display: none;
}

@media only screen and (max-width: 1240px) {
  .status {
    display: none;
  }

  .status.responsive {
    display: block;
    width: 50px;
  }
}

.timeline::after {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 9px;
  left: 21px;
  width: calc(100% - 21px);
  background: #dcdcdc;
  border-radius: 3px;
  z-index: 1;
}

.beforeReappearance .timeline::after {
  background:
    repeating-linear-gradient(
      to right,
      var(--color-timeline) 0,
      var(--color-timeline) 5px,
      #fff 5px,
      #fff 10px
    );
}

@media only screen and (max-width: 1240px) {
  .timeline::after {
    bottom: 12px;
  }
}

.actionBody .status.last .timeline::after {
  width: 0;
}

.statusLabel {
  margin-right: 15px;
  font-weight: 500;
  color: #333;
}

.statusDate {
  margin-right: 15px;
  color: #333;
}

.showDetailsButton {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  background: none;
  padding: 0;
}

.showDetailsButton:hover {
  background: none;
  padding: 0;
}

.actionBody:hover .showDetailsButton path {
  fill: var(--color-princ-monibrand);
}

.showDetailsIcon {
  color: var(--color-link-menu);
  font-size: 30px;
}
