.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0 20px;
}

.label {
  color: var(--color-bg-alt);
  width: 100%;
  cursor: pointer;
  font-size: 1rem;
}

.label::first-letter {
  text-transform: uppercase;
}

.input {
  margin-right: 5px;
}

.choicecontainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 8px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid rgb(0 0 0 / 10%);
}

.labelRadio {
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.8em;
}

.input[data-error="true"] {
  border: 1px solid var(--color-txt-error-light);
  animation: error 500ms 3;
}

@keyframes error {
  from {
    border: 1px solid var(--color-txt-error);
    box-shadow: 0 0 5px var(--color-txt-error-light);
  }

  to {
    border: 1px solid var(--color-txt-error-light);
  }
}
