.page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-shadow);
  z-index: 14;
}

.panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: grid;
  border: 1px solid var(--color-bg-lightgrey);
  padding: 20px;
  background: var(--color-bg-tab);
  gap: 15px;
  transition: 0.3s ease-in-out;
  z-index: 150;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  min-width: 520px;
}

.panel[data-open="false"] {
  transform: translateX(100%);
}

.panel[data-open="true"] {
  transform: translateX(0);
}
