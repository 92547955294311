.bar {
  height: 10px;
  border-radius: 3px;
  margin: 10px 5px;
  background: var(--color-bg-alt);
  animation: shade 1.5s ease-in-out infinite alternate-reverse;
}

@keyframes shade {
  0% {
    transform: translateZ(0);
    opacity: 0.4;
  }

  50% {
    transform: translateZ(0);
    opacity: 0.2;
  }

  100% {
    transform: translateZ(0);
    opacity: 0.4;
  }
}
