.title {
  align-items: center;
  color: var(--color-link-menu);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  gap: 5px;
  padding: 5px 0;
}

.title.closed {
  padding-bottom: 10px;
}

.title.collapsed {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.title[data-style="big"] {
  color: var(--color-title);
  font-size: 1.5em;
}

.titleTxt::first-letter {
  text-transform: uppercase;
}

.icon,
.title {
  transition: 0.3s ease-in-out;
}

.icon {
  width: 16px;
  display: flex;
  align-items: center;
}

.icon[data-down="true"] {
  transform: rotate(0.5turn);
}

.titleContent {
  margin-left: 10px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.title:hover .titleContent {
  font-weight: 600;
}

.title:hover .icon path {
  fill: var(--color-princ-monibrand);
}
