.table {
  border: 0;
  border-collapse: collapse;
  box-sizing: border-box;
  margin: 0 0 10px;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: white;
}

.tableHeader {
  border-bottom: solid 1px var(--color-bg-lightgrey);
  color: var(--color-princ-monibrand-second);
  font-size: 14px;
  margin-bottom: 15px;
  text-align: left;
  padding: 15px 8px;
}

.noAdvertisement {
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderContainer {
  margin-top: 30px;
  margin-bottom: 30px;
}
