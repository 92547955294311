.container {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 10px;
}

.header {
  background: var(--color-princ-monibrand);
  padding: 5px;
}

.logo {
  max-width: 200px;
}
