.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px;
  position: relative;
  border-bottom: 1px solid var(--color-border);
}

.range {
  min-width: 200px;
  max-width: 250px;
  font-size: x-small;
}

.containerRange {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.title {
  /* opacity: 0.5; */
  font-size: 0.8em;
  color: #333;
  font-weight: 500;
}

.title::first-letter {
  text-transform: capitalize;
}

.container:focus-within {
  z-index: 9999 !important;
}

.container > div:nth-child(1) > div:nth-child(1) {
  font-size: x-small;
}
