.container {
  display: flex;
  gap: 5px;
  align-items: center;
  align-content: center;
}

.filter {
  display: flex;
  gap: 10px;
  border-radius: 5px;
  padding: 6px 8px;
  border: 1px solid var(--color-border);
  transition: 0.2s ease-in-out;
  font-size: 12px;
  line-height: normal;
  color: var(--color-title);
  align-items: center;
  align-content: center;
  white-space: nowrap;
  user-select: none;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  color: var(--color-princ-monibrand);
}

.normal {
  cursor: auto;
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.filter::first-letter {
  text-transform: uppercase;
}

.cross {
  cursor: pointer;
  width: 8px;
}

.cross:hover path {
  fill: var(--color-princ-monibrand);
}

.clearAll {
  transition: 200ms ease-in-out;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 500;
  padding-left: 2px;
  color: var(--color-link-menu);
  user-select: none;
}

.clearAll:hover {
  color: var(--color-princ-monibrand);
}
