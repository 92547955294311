.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  position: relative;
  width: 50px;
  height: 50px;
}

.circleExt,
.circleMid,
.circleInt {
  position: absolute;
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.circleMid {
  transform: scale(0.65);
}

.circleInt {
  transform: scale(0.3);
}

.subCircleExt,
.circleExtwhite {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--color-princ-monibrand);
}

.circleExtwhite {
  transform: scale(0.87);
  background: #fff;
  border-radius: 50%;
}

.triangle {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border-radius: 0 100%/100%;
  transform-origin: left bottom;
  background: rgb(255 255 255 / 50%);
}

.circleExt .triangle {
  transform: rotate(45deg);
  animation: rotate 2s ease-in-out infinite;
}

.circleMid .circleExtwhite {
  transform: scale(0.82);
}

.circleMid .triangle {
  animation: rotate 2s ease-in-out infinite;
  animation-delay: 0.4s;
}

.circleInt .triangle {
  animation: rotate 2s ease-in-out infinite;
  animation-delay: 0.8s;
}

.circleInt .circleExtwhite {
  transform: scale(0.65);
}

@keyframes rotate {
  0% {
    transform: rotate(1turn);
  }

  25% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(45deg);
  }
}
