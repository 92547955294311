.container {
  min-width: 300px;
}

.buttons {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  align-content: center;
  margin-top: 20px;
}

.title {
  font-size: 1.1em;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  font-weight: normal;
}

.title::first-letter {
  text-transform: uppercase;
}

.title::after {
  content: ".";
}

@media screen and (max-width: 400px) {
  .container {
    min-width: initial;
    width: calc(100% - 20px);
  }
}
