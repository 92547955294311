.container {
  margin: 10px;
}

.box {
  display: flex;
  overflow: hidden;
  align-items: flex-end;
}

.navlink {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  justify-content: center;
  padding: 5px;
}

.navlink.last {
  font-weight: 500;
  font-size: 20px;
}

.link {
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  color: #333;
  margin-bottom: 2px;
}

.link.last {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
}

.linkSeparator {
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  color: #333;
  padding: 5px 0;
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
}

.section {
  cursor: initial;
}

.link::first-letter,
.section span::first-letter {
  text-transform: uppercase;
}

.navlink[data-key="true"] {
  background: var(--color-bg-lightgrey);
  cursor: default;
}

@keyframes arrival {
  0% {
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 0 49%, 0 0);
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  80% {
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 0 49%, 0 0);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
