.container {
  display: inline-grid;
  grid-template-columns: auto 1fr;
  gap: 5px;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

.container div::first-letter {
  text-transform: capitalize;
}

.round {
  --status-size: 6px;

  width: var(--status-size);
  height: var(--status-size);
  border-radius: 50%;
  background: var(--color-bg-lightgrey);
}

.round[data-color="new"] {
  background: var(--color-azure);
}

.round[data-color="reminded"] {
  background: var(--color-orange);
}

.round[data-color="requested"] {
  background: var(--color-dark-blue);
}

.round[data-color="ignored"] {
  background: var(--color-txt-error);
}

.round[data-color="refused"] {
  background: var(--color-red);
}

.round[data-color="inprogress"] {
  background: var(--color-princ-monibrand);
}

.round[data-color="accepted"] {
  background: var(--color-green);
}

.round[data-color="blocked"] {
  background: var(--color-bg-alt);
}

.round[data-color="disappeared"] {
  background: var(--color-dark-green);
}
