.container {
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;
}

.radio {
  --radio-size: 15px;
  --radio-before-position: 3px;

  width: var(--radio-size);
  height: var(--radio-size);
  border: 1px solid var(--color-border-alt);
  border-radius: 50%;
  position: relative;
  transition: 200ms ease-in-out;
}

.label {
  cursor: pointer;
}

.radio::before {
  content: "";
  position: absolute;

  --radio-before-position: 2px;

  top: var(--radio-before-position);
  bottom: var(--radio-before-position);
  right: var(--radio-before-position);
  left: var(--radio-before-position);
  background: var(--color-princ-monibrand);
  border-radius: 50%;
  transform: scale(1);
  transition: 200ms ease-in-out;
}

.radio[data-check="false"]::before {
  transform: scale(0);
}

.container:hover .radio[data-check="false"]::before {
  transform: scale(0.4);
}

.container:hover .radio[data-check="true"]::before {
  transform: scale(1.2);
}
