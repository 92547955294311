.container {
  position: relative;

  /* display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr; */
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  /* grid-column: 1/3; */
}

.body {
  display: flex;
  height: calc(100vh - 65px);
}
