.container {
  position: relative;
  width: 50px;
  height: 20px;
  background: var(--color-link-menu);
  border-radius: 20px;
  cursor: pointer;
}

.container[data-isactive="true"] {
  background: var(--color-princ-monibrand);
}

.container:disabled {
  opacity: 0.2;
}

.container::before {
  display: none;
}

.ball {
  background: #fff;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
}

.container:hover .ball {
  transform: scale(1.2);
}

.ball[data-isactive="true"] {
  background: #fff;
  left: calc(100% - 19px);
  transition: 0.3s ease-in-out;
}
