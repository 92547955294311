.container {
  display: flex;
  gap: 10px;
  padding: 0 5px 8px 20px;
}

.container,
.actions {
  align-items: center;
}

.select {
  color: var(--color-princ-monibrand-second);
}

.bold {
  font-weight: bold;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  color: var(--color-bg-alt);
  transition: 200ms ease-in-out;
}
