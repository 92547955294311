.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.external {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.externalLink {
  color: var(--color-link-menu);
}

.externalLink:hover {
  color: var(--color-princ-monibrand);
}

.searchGlasses {
  color: var(--color-link-menu);
  cursor: pointer;
  align-items: center;
  display: flex;
}

.searchGlasses:hover {
  color: var(--color-princ-monibrand);
}
