.container {
  display: flex;
  flex-direction: column;
  gap: 45px;
  width: 100%;
}

.section {
  display: flex;
  flex-direction: column;
}

.checkOptions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.checkTitle {
  color: black;
  font-weight: bold;
  width: 100%;
  font-size: 1rem;
}

.checkbox {
  padding-left: 15px;
}
