.container {
  font-size: 1em;
  display: flex;
  flex-direction: column;
  transition: 200ms ease-out;
  padding-bottom: 10px;
}

.container.collapsed {
  padding-bottom: 0;
  justify-content: center;
}

.link {
  display: grid;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-top: 8px;
  color: var(--color-link-menu);
  grid-template-columns: auto 1fr;
  transition: 300ms ease-out;
  cursor: pointer;
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  width: 16px;
}

.link.collapsed {
  display: flex;
  padding: 8px 0;
}

.link.standalone {
  padding: 10px 0;
}

.standalone .icon {
  margin-right: 15px;
}

.standalone,
.collapsed {
  margin-left: 0;
}

.collapsed {
  margin-right: 0;
}

.bullet {
  width: 4px;
  height: 4px;
  border: 1px solid var(--color-link-menu);
  border-radius: 50%;
}

.bulletActive {
  border-color: var(--color-princ-monibrand);
}

.collapsed .link {
  margin: unset;
}

.collapsed .icon {
  margin-right: 0;
}

.label {
  padding: 0 20px 0 0;
  transition: 200ms ease-out;
  line-break: auto;
}

.link:hover .label {
  font-weight: 600;
}

.link:hover .icon path {
  fill: var(--color-princ-monibrand);
}

.link:hover .bullet {
  border-color: var(--color-princ-monibrand);
}

.label::first-letter {
  text-transform: uppercase;
}

/* @media screen and (max-width: 1450px) {
  .icon {
    display: none;
  }

  .link {
    margin: 0 2px 0 5px;
    padding: 5px 0;
  }
} */
