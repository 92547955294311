.container {
  display: flex;
  flex-direction: column;

  /* width: 450px; */

  box-shadow: 0 2px 4px #00000029;
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
}

.centered {
  justify-content: center;
  align-items: center;
}

.container.disabled {
  opacity: 0.3;
}

.container.noBody {
  height: 64px;
}

.container.clickable {
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-title);
}

.header > h3 {
  font-weight: 500;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

.header .toolbar {
  display: flex;
  align-items: center;
}

.header .toolbar .search {
  color: var(--color-link-menu);
  font-size: 16px;
  cursor: pointer;
  margin-right: 20px;
}

.body {
  margin-top: 25px;
  flex-grow: 1;
  display: flex;
  height: 328px;
}

.body.noHeight {
  height: unset;
}

.body.noHeader {
  margin-top: unset;
}

.openToggle {
  color: var(--color-link-menu);
  font-size: 16px;
  cursor: pointer;
}
