.containerFilters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 5px;
  padding: 10px;
  margin-right: 0;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 15px;
}

.main {
  position: relative;
  padding: 10px;
  flex: 1 1 auto;
}

.filters {
  margin: 0 5px;
}
