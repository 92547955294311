.container,
.id,
.profile {
  display: flex;
}

.container {
  gap: 10px;
  align-items: center;
  padding: 5px;
  position: relative;
  cursor: pointer;
  justify-content: flex-end;
}

.profile {
  flex-direction: column;
  align-items: flex-end;
  color: #fff;
}

.id,
.profile {
  gap: 5px;
}

.id {
  align-items: center;
  justify-content: flex-end;
}

.type {
  font-size: 0.9em;
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--color-princ-monibrand-second);
}

.avatar img {
  width: 100%;
  height: auto;
}

.firstName::first-letter,
.lastName {
  text-transform: uppercase;
}
