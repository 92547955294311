.container {
  display: inline-block;
  position: relative;

  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;

  cursor: default;
  z-index: 100;
}

.container.flex {
  display: flex;
}

.info {
  color: var(--color-link-menu);
  cursor: pointer;
  font-size: 14px;
  width: 14px;
  height: 14px;
  margin: 3px;
}

.tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  white-space: nowrap;
}

.bottomRight {
  transform: none;
  left: calc(50% - 10px);
}

.box {
  color: white;
  background: var(--color-link-menu);
  box-shadow: 2px 2px 5px var(--color-shadow);
  border-radius: 4px;
  padding: 6px;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
}

.intentSuccess {
  background: var(--color-green);
}

.tip[data-arrow="true"]::before {
  content: "";
  position: absolute;
  background: var(--color-link-menu);
  width: var(--tooltip-arrow-size);
  height: var(--tooltip-arrow-size);
  transform: rotate(-45deg);
  z-index: -1;
  clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

.tip[data-direction="top"] {
  bottom: calc(100% + 8px);
}

.tip[data-direction="top"]::before {
  left: calc(50% - var(--tooltip-arrow-size) / 2);
  top: calc(100% - var(--tooltip-arrow-size) / 2);
}

.tip[data-direction="right"] {
  left: calc(100% + 10px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.tip[data-direction="right"]::before {
  left: calc(-1px - var(--tooltip-arrow-size) / 2);
  top: 50%;
  transform: rotate(45deg) translateX(0) translateY(-50%);
  border-right-color: var(--color-link-menu);
}

.tip[data-direction="bottom"] {
  top: calc(100% + 8px);
}

.tip[data-direction="bottom"]::before {
  left: calc(50% - var(--tooltip-arrow-size) / 2);
  bottom: calc(100% - var(--tooltip-arrow-size) / 2);
  transform: rotate(135deg);
}

.tip[data-direction="left"] {
  left: auto;
  right: calc(100% + 10px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.tip[data-direction="left"]::before {
  left: auto;
  right: calc(0px - var(--tooltip-arrow-size) / 2);
  top: 50%;
  transform: translateX(0) translateY(-50%) rotate(-135deg);
}
