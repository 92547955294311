.container {
  position: fixed;
  right: 30px;
  top: 15px;
  width: 30px;
  height: 30px;
  transition: 0.2s ease-in-out;
  transform: rotate(90deg);
  z-index: 5;
}

.navBarToggle {
  position: relative;
  width: 30px;
  height: 21px;
  cursor: pointer;
}

.bar {
  position: absolute;
  width: 50%;
  height: 3px;
  background: var(--color-bg-alt);
  transition: 0.3s ease-in-out;
}

.navBarToggle[data-isopen="false"] > .bar[data-bar="topLeft"],
.navBarToggle[data-isopen="false"] > .bar[data-bar="topRight"] {
  top: 0;
}

.navBarToggle[data-isopen="true"] > .bar[data-bar="bottomLeft"],
.navBarToggle[data-isopen="true"] > .bar[data-bar="topLeft"],
.navBarToggle[data-isopen="false"] > .bar[data-bar="bottomLeft"],
.navBarToggle[data-isopen="false"] > .bar[data-bar="middleLeft"],
.navBarToggle[data-isopen="false"] > .bar[data-bar="topLeft"] {
  left: 0;
  border-radius: 3px 0 0 3px;
}

.navBarToggle[data-isopen="true"] > .bar[data-bar="bottomRight"],
.navBarToggle[data-isopen="true"] > .bar[data-bar="topRight"],
.navBarToggle[data-isopen="false"] > .bar[data-bar="bottomRight"],
.navBarToggle[data-isopen="false"] > .bar[data-bar="middleRight"],
.navBarToggle[data-isopen="false"] > .bar[data-bar="topRight"] {
  right: 0;
}

.navBarToggle[data-isopen="false"] > .bar[data-bar="bottomLeft"],
.navBarToggle[data-isopen="false"] > .bar[data-bar="bottomRight"] {
  bottom: 0;
}

.navBarToggle[data-isopen="true"] > .bar[data-bar="middleLeft"],
.navBarToggle[data-isopen="true"] > .bar[data-bar="middleRight"],
.navBarToggle[data-isopen="false"] > .bar[data-bar="middleLeft"],
.navBarToggle[data-isopen="false"] > .bar[data-bar="middleRight"] {
  top: calc(50% - 1.5px);
}

.navBarToggle[data-isopen="true"] > .bar[data-bar="bottomRight"],
.navBarToggle[data-isopen="true"] > .bar[data-bar="topRight"],
.navBarToggle[data-isopen="false"] > .bar[data-bar="bottomRight"],
.navBarToggle[data-isopen="false"] > .bar[data-bar="topRight"] {
  border-radius: 0 3px 3px 0;
}

.navBarToggle[data-isopen="true"] > .bar[data-bar="topLeft"],
.navBarToggle[data-isopen="true"] > .bar[data-bar="topRight"] {
  top: 3px;
  width: 60%;
}

.navBarToggle[data-isopen="true"] > .bar[data-bar="bottomRight"],
.navBarToggle[data-isopen="true"] > .bar[data-bar="topLeft"] {
  transform: rotate(45deg);
}

.navBarToggle[data-isopen="true"] > .bar[data-bar="bottomLeft"],
.navBarToggle[data-isopen="true"] > .bar[data-bar="topRight"] {
  transform: rotate(-45deg);
}

.navBarToggle[data-isopen="true"] > .bar[data-bar="middleLeft"],
.navBarToggle[data-isopen="true"] > .bar[data-bar="middleRight"] {
  width: 0;
}

.navBarToggle[data-isopen="true"] > .bar[data-bar="bottomLeft"],
.navBarToggle[data-isopen="true"] > .bar[data-bar="bottomRight"] {
  bottom: 3px;
  width: 60%;
}

.navBarToggle[data-isopen="true"] > .bar[data-bar="middleLeft"] {
  left: 50%;
}

.navBarToggle[data-isopen="true"] > .bar[data-bar="middleRight"] {
  right: 50%;
}
