.container {
  padding: 0 20px;
  margin-top: 46px;
  margin-bottom: 46px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.topContainer {
  background-color: var(--color-bg-extra-light);
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: min-content;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.mainContainer {
  flex: 1 1 100%;
  height: 100%;
}

.mainPanel {
  height: 100%;
}
