.menu {
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  background: #fff;
  z-index: 5;
  padding: 10px;
  overflow-y: auto;
  transition: 0.3s ease-in-out;
  box-shadow: 5px 25px 20px var(--color-shadow);
  border-top: 1px solid var(--color-princ-monibrand);
}

.menu[data-open="false"] {
  right: 100%;
  left: -100%;
}

.menu[data-open="true"] {
  right: 0;
  left: 0;
}
