@font-face {
  font-family: "Work Sans";
  src: url("../fonts/WorkSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Work Sans Medium";
  src: url("../fonts/WorkSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: DM-Serif;
  src: url("../fonts/DMSerifDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: monibrand-picto;
  src: url("../fonts/monibrand-picto.ttf") format("truetype");
}

@font-face {
  font-family: "Work Sans";
  font-weight: 500;
  src: url("../fonts/WorkSans-Medium.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

.ScrollbarsCustom-Scroller > div {
  display: flex;
  flex-direction: column;
}

.ScrollbarsCustom-TrackY {
  background-color: #fff !important;
  width: 6px !important;
  top: 0 !important;
  height: 100% !important;
}

.table .ScrollbarsCustom-TrackY {
  display: none;
}

.table:hover .ScrollbarsCustom-TrackY {
  display: block;
}

.ScrollbarsCustom.hidden .ScrollbarsCustom-TrackY {
  display: none;
}

.ScrollbarsCustom-ThumbY {
  background-color: rgb(221 221 221 / 80%) !important;
}

.ScrollbarsCustom-ThumbY:hover {
  background: rgb(51 51 51 / 25%) !important;
}

.countLabel {
  text-align: center;
  opacity: 0.5;
  padding: 10px;
}

.countLabel::before {
  content: "(";
}

.countLabel::after {
  content: ")";
}

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  font-size: 14px;
}

.link {
  transition: 200ms ease-in-out;
  font-weight: 500;
  color: rgb(43 48 95);
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

[data-disabled="true"] {
  opacity: 0.4;
}

input::placeholder,
div[class*="placeholder"] {
  font-size: 14px !important;
}

button,
a.button {
  background: var(--color-princ-monibrand);
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  transition: 200ms ease-in-out;
  color: black;
  text-decoration: none;
  font-family: "Work Sans Medium", sans-serif;
  position: relative;
  padding: 9px 15px;
}

button:hover {
  background: var(--color-princ-monibrand-hover);
  background: transparent;
}

button.reset,
button[type="reset"] {
  background: transparent;
}

button.reset:hover,
button[type="reset"]:hover {
  background: transparent;
}

button.minimal,
a.button.minimal {
  background: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  transition: 200ms ease-in-out;
  color: black;
  text-decoration: none;
  font-family: "Work Sans Medium", sans-serif;
  position: relative;
  padding: 5px;
}

a[aria-current="page"] {
  font-weight: bold;
}

button[disabled] {
  opacity: 0.5;
}

button[data-loading="true"][disabled] {
  opacity: 01;
  background: var(--color-princ-monibrand);
}

button:disabled {
  opacity: 0.5;
}

.bold {
  font-weight: bold;
}

.capitalize {
  text-transform: capitalize;
}

[data-highlight="true"] {
  background: yellow;
  padding: 3px;
}

[data-highlightborder="true"] {
  padding-bottom: 3px;
  border-bottom: 5px solid rgb(211 211 0);
}

th::first-letter,
th > span::first-letter {
  text-transform: uppercase;
}

div[class*="placeholder"]::first-letter {
  text-transform: uppercase !important;
}

.close {
  display: flex;
  flex-direction: row-reverse;
  font-size: 20px;
  margin: 0;
  cursor: pointer;
  color: var(--color-bg-alt);
  transition: 200ms ease-in-out;
}

.close:hover {
  color: var(--color-princ-monibrand);
}

.invisible {
  display: none;
}

.flag-icon.big {
  width: 2.3em;
  height: 25px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

input::placeholder::first-letter {
  text-transform: uppercase;
}

/* ---- calendar --- */

.rdrCalendarWrapper {
  border-radius: 5px;
}

.rdrDateDisplayWrapper,
.rdrMonthAndYearWrapper,
.rdrMonths {
  padding: 20px;
}

.rdrMonths {
  min-height: 308px;
}

/* --- animation --- */

.accordion-enter {
  opacity: 0;
}

.accordion-enter.accordion-enter-active {
  opacity: 1;
  transition: 300ms ease-in-out;
}

.accordion-leave {
  opacity: 0;
}

.accordion-exit {
  opacity: 1;
}

.accordion-exit-done {
  display: none;
}

.accordion-exit-active {
  opacity: 0;
  transition: 300ms ease-in-out;
}

.accordion-leave.accordion-leave-active {
  opacity: 0.01;
  transition: 300ms ease-in-out;
}

.rc-slider-track {
  background-color: var(--color-princ-monibrand) !important;
  transition: 200ms ease-in-out imp !important;
}

.rc-slider-handle-dragging {
  border-color: var(--color-princ-monibrand-second) !important;
  box-shadow: 0 0 2px 5px rgb(255 255 150 / 50%) !important;
}

.fade-enter {
  opacity: 0;
}

.fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

.pointer {
  cursor: pointer;
}

.slide-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-enter-active {
  transition: opacity 300ms;
}

.slide-exit {
  opacity: 0;
  transform: translateX(0);
}

.slide-exit-active {
  opacity: 1;
  transform: translateX(100%);
}

.shutter-enter {
  opacity: 0;
  transform: scaleY(0.98) translateY(20%);
}

.shutter-enter-active {
  opacity: 1;
  transform: scaleY(1)  translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.shutter-exit {
  opacity: 1;
}

.shutter-exit-active {
  opacity: 0;
  transform: scaleY(0.98) translateY(20%);
  transition: opacity 300ms, transform 300ms;
}

.tooltip-enter {
  opacity: 0.5;
  transform: translateY(20%);
}

.tooltip-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.tooltip-exit {
  opacity: 1;
}

.tooltip-exit-active {
  opacity: 0;
  transform: translateY(20%);
  transition: opacity 300ms, transform 300ms;
}

.elegantTitle {
  font-family: DM-Serif, sans-serif;
  font-size: 1.8em;
  font-weight: normal;
}

.elegantTitle::first-letter {
  text-transform: uppercase;
}

.datePicker .rdrDateRangePickerWrapper {
  flex-direction: column;
  display: inline !important;
}

.datePicker .rdrDefinedRangesWrapper {
  display: none !important;
}

.rdrDay:hover {
  box-shadow: none;
  background: white;
}

.icon {
  cursor: pointer;
  transition: 0.2s ease-in-out;
  color: var(--color-link-menu);
}

.icon:hover {
  color: var(--color-princ-monibrand);
}

.rdrDayToday .rdrDayNumber span::after {
  background: var(--color-princ-monibrand);
}

.rdrWarning {
  display: none;
}

@media screen and (max-width: 700px) {
  .rdrDateRangePickerWrapper {
    flex-direction: column;
    display: inline !important;
  }

  .rdrDefinedRangesWrapper {
    display: none !important;
  }
}

[class^="moni-icon"],
[class*=" moni-icon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: monibrand-picto, sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/** ----  TOOLTIP ---- */

.rpt-arrow {
  display: none !important;
}

.rpt-shadow-container {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%) !important;
}

@keyframes rpt-slideUpDown {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes rpt-slideUpDown-out {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}

/** ---- Filters left panel ---- */

.filters-exit-done {
  display: none;
}

.filters-enter-done {
  display: flex;
}

/** ---- Hoverable creatable select --- */

/* stylelint-disable */
.taglist__placeholder {
  text-align: left;
}

.hoverable .taglist__control {
  box-shadow: none;
}

.hoverable .taglist__indicators {
  visibility: hidden;
}

tr:hover .hoverable .taglist__control,
.hoverable-trigger:hover .hoverable .taglist__control,
.hoverable-trigger.hoverable:hover .taglist__control {
  background-color: #fff;
  box-shadow: -1px -1px 0 var(--color-border), 1px -1px 0 var(--color-border), -1px 1px 0 var(--color-border), 1px 1px 0 var(--color-border);
}

.taglist__control--menu-is-open {
  background-color: #fff !important;
  box-shadow: -1px -1px 0 var(--color-border), 1px -1px 0 var(--color-border), -1px 1px 0 var(--color-border), 1px 1px 0 var(--color-border) !important;
}

tr:hover .hoverable .taglist__indicators,
.hoverable-trigger:hover .hoverable .taglist__indicators,
.hoverable-trigger.hoverable:hover .taglist__indicators {
  visibility: visible;
}

.taglist__control--menu-is-open .taglist__indicators {
  visibility: visible;
}

/* stylelint-enable */
