.container {
  margin: 10px;
  display: inline-grid;
  grid-template-columns: auto auto;
  gap: 10px;
}

.actions,
.container {
  align-items: center;
}

.select {
  color: var(--color-princ-monibrand-second);
}

.bold {
  font-weight: 700;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  color: var(--color-bg-alt);
  transition: 0.2s ease-in-out;
}

.action {
  padding: 5px;
  border-radius: 3px;
  border: 1px solid var(--color-bg-lightgrey);
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.action::first-letter {
  text-transform: uppercase;
}

.action:hover {
  box-shadow: 3px 3px 5px var(--color-shadow);
}
