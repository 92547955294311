.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  overflow-y: hidden;
  min-width: 400px;
}

.buttons {
  margin-top: 10px;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.title {
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.plus {
  text-align: center;
  font-size: 0.8em;
  color: var(--color-orange);
  cursor: pointer;
}

.desc {
  text-align: center;
  font-size: 1.2em;
  padding: 10px;
}

@media screen and (max-width: 500px) {
  .container {
    min-width: inherit;
    width: 80%;
  }
}
