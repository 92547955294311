.container {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  align-items: center;
  gap: 5px;
  color: var(--color-bg-alt);
  white-space: nowrap;
}

.counter {
  font-weight: 700;
  color: var(--color-princ-monibrand);
  padding: 0;
  margin: 0;
}

.counter[data-color="normal"] {
  color: var(--color-princ-monibrand);
}

.counter[data-color="secondary"] {
  color: var(--color-princ-monibrand-second);
}

.counter[data-color="green"] {
  color: var(--color-green);
}

.counter[data-color="azure"] {
  color: var(--color-azure);
}

.counter[data-color="alert"] {
  color: var(--color-txt-error);
}

.counter[data-color="grey"] {
  color: var(--color-bg-alt);
}

.counter[data-color="orange"] {
  color: var(--color-orange);
}

.counter[data-color="red"] {
  color: var(--color-red);
}

.counter[data-color="dark-blue"] {
  color: var(--color-dark-blue);
}
