.container {
  width: 1020px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 20px;
}

.header {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
}

.header .search {
  color: var(--color-link-menu);
  font-size: 16px;
  cursor: pointer;
  margin-right: 20px;
}

.conversations {
  display: flex;
  flex-direction: column;
  margin: 30px 20px 20px;
  flex: 1 1 auto;
  font-size: 14px;
}
