.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  height: 100%;
  cursor: pointer;
  font-size: small;
  line-break: loose;
}

/* .txt {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  font-weight: 500;
  font-size: 1.1rem;
}

.txt div:nth-child(1)::first-letter {
  text-transform: uppercase;
}

.txt div[data-size="big"] {
  font-weight: 700;
  font-size: 1.1rem;
} */

/* .defaultDate {
  width: 100%;
  text-align: center;
  color: var(--color-princ-monibrand-second);
  font-style: italic;
  padding: 5px;
}

.noDate {
  width: 100%;
  padding: 15px;
  text-align: center;
  opacity: 0.5;
  font-style: italic;
}

.delete {
  font-size: 1.1rem;
  opacity: 0.5;
}

.choices {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  padding: 10px;
  font-size: 1rem;
  text-align: center;
  align-items: stretch;
} */

.choicesList {
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 5px;
}

.choicesListLabel {
  font-size: 12px;
  text-transform: capitalize;
  color: #333;
  font-weight: 500;
}

.selectOptionSelected > div {
  border-color: #faba2e;
}

/* .choice {
  border: 1px solid var(--color-border);
  padding: 5px;
  display: flex;
  flex-direction: column;
  transition: 0.2s ease-in-out;
  justify-content: center;
}

.choice[data-current="true"],
.choice:hover {
  background: var(--color-princ-monibrand);
}

.choiceTxt::first-letter {
  text-transform: uppercase;
} */
