.main {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 auto;
}

.actions {
  background-color: rgb(221 221 221 / 60%);
  flex: 1 1 auto;
}

.title {
  color: var(--color-title);
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
}

.noResultContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.noResultTitle {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: var(--color-title);
  margin-top: 20px;
  margin-bottom: 8px;
}

.noResultText {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-title);
}

.panelLoading {
  height: calc(100% / 5);
  margin: 15px;
  box-shadow: 0 2px 10px #00000016;
  border-radius: 5px;
  background: var(--color-loading);
  animation: shade 1.5s ease-in-out infinite alternate-reverse;
}

@keyframes shade {
  0% {
    transform: translateZ(0);
    opacity: 0.5;
  }

  50% {
    transform: translateZ(0);
    opacity: 0.3;
  }

  100% {
    transform: translateZ(0);
    opacity: 0.5;
  }
}

.panelLoading:hover {
  box-shadow: 0 2px 10px rgb(0 0 0 / 40%);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transform: translateY(-5px);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
