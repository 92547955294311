.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-shadow);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.modal {
  background-color: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  border: 1px solid var(--color-bg-alt);
  border-radius: 10px;
  box-shadow: 5px 5px 5px var(--color-shadow);
  font-size: 1.2rem;
  min-width: 600px;
  gap: 20px;
}

.title {
  margin: 10px 10px 15px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-princ-monibrand);
  font-weight: 700;
  font-size: 1.5rem;
}

.form,
.form input {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: left;
}

.form {
  gap: 25px;
}

.link {
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  border: 1px solid var(--color-border);
  transition: 0.2s ease-in-out;
}

.link:hover {
  box-shadow: 5px 5px 0 var(--color-bg-extra-light);
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
