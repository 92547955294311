.container {
  display: flex;
  justify-content: center;
}

.link {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 5px;
  align-items: center;
}
