.wrapper {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}

.wrapper:hover {
  background-color: transparent;
}

.box {
  color: white;
  background: var(--color-link-menu);
  box-shadow: 2px 2px 5px var(--color-shadow);
  border-radius: 4px;
  padding: 6px;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
  z-index: 1000px;
}

.box::first-letter {
  text-transform: capitalize;
}
