.boxContainer {
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  padding: 2px 8px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.boxOption {
  border-radius: 5px;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  padding: 5px;
  color: white;
  background: var(--color-azure);
}

.boxOptionLabel {
  border-radius: 2px;
  color: white;
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.boxRemove {
  align-items: center;
  border-radius: 2px;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
}

.boxRemoveSvg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}

.boxRemoveSvg:hover {
  background-color: rgb(0 0 0 / 10%);
  color: rgb(255 255 255);
}
