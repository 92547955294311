.container {
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  width: 800px;
  max-height: 500px;
  grid-gap: 30px;
  gap: 30px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding-bottom: 40px;
}
