.form {
  width: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}

.containerButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 15px 0;
}

@media screen and (max-width: 400px) {
  .form {
    min-width: inherit;
  }
}
