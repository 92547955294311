.container {
  display: flex;
  flex-direction: column;
  margin: 0 25px 13px;
}

.header {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-link-menu);
  margin-bottom: 3px;
}

.content {
  color: var(--color-title);
  font-size: 14px;
  white-space: break-spaces;
}
