.container {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  height: 100vh;
}

.header {
  grid-column: 1/3;
}

.nav {
  display: flex;
  flex-direction: column;
  position: relative;
}

.main {
  display: flex;
  flex-direction: column;
}

.main.menuMinimized,
.main.menuHovered {
  margin-left: 48px;
}
