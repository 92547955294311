.container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-items: start;
  width: 800px;
  gap: 30px;
  height: 100%;
  padding-bottom: 20px;
}

@media screen and (max-width: 1450px) {
  .container {
    gap: 0;
  }
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.subtitle {
  text-align: center;
  font-size: 0.7em;
  color: var(--color-grey);
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
  padding-top: 30px;
  padding-bottom: 20px;
}

@media screen and (max-width: 1450px) {
  .buttons {
    padding-bottom: 0;
  }
}

.title {
  font-size: 25px;
  text-align: center;
  margin-top: 40px;
  color: black;
}

@media screen and (max-width: 1450px) {
  .title {
    margin-top: 0;
  }
}

.title::first-letter {
  text-transform: uppercase;
}

.content {
  width: 100%;
  height: 100%;
}

.children {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .container {
    width: calc(100vw - 80px);
  }

  .title {
    font-size: 1em;
    margin-top: 0;
  }

  .children {
    margin: 0;
  }
}

@media screen and (max-height: 480px) {
  .container {
    min-height: inherit;
  }
}
