.container {
  margin: 10px 0;
  border: 1px solid var(--color-txt-error);
  padding: 10px;
}

.summary {
  color: var(--color-bg-alt);
  text-transform: uppercase;
  font-size: 0.9em;
}

.list {
  font-size: 0.8em;
  color: var(--color-txt-error-light);
  font-style: italic;
  list-style-type: "-";
}
