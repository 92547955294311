.container {
  padding: 0 20px;
  margin-bottom: 46px;
}

.topContainer {
  background-color: var(--color-bg-extra-light);
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: min-content;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.notesContainer {
  display: flex;
  gap: 20px;
}

.notes {
  flex: 1;
  box-shadow: 0 33px 24px -26px #768d8c72 !important;
}
