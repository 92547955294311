.container {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 5px;
  padding: 8px 10px;
  border: 1px solid var(--color-border);
  transition: 0.2s ease-in-out;
  font-size: 14px;
  line-height: normal;
  color: var(--color-title);
  align-items: center;
  align-content: center;
  white-space: nowrap;
  user-select: none;
  position: relative;
  cursor: pointer;
  margin-right: 15px;
}

.container:hover {
  background-color: var(--color-princ-monibrand);
}

.filtersHint {
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 50%;
  background-color: var(--color-princ-monibrand);
  color: #fff;
  border: solid 1px #fff;
  padding: 3px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
