.container,
.container[data-separator="true"] {
  display: flex;
  flex-direction: column;
}

.container[data-separator="true"] {
  position: relative;
  margin-top: 10px;
  padding-top: 10px;
}

.container[data-separator="true"]::before {
  content: "";
  top: 0;
  position: absolute;
  left: -25px;
  right: -25px;
  height: 1px;
  background: var(--color-border);
}
