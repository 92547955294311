.container {
  display: flex;
  text-align: right;
}

.button {
  font-size: 0.9em;
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
  transition: 200ms ease-in-out;
}

.button:hover {
  color: white;
}
