.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  margin: 5px;
  gap: 10px;
  font-size: 3em;
  font-size: 1rem;
  border-radius: 3px;
  background: #fff;
  transition: 0.2s ease-in-out;
  z-index: 10;
}

.perPageContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.txtPerPage {
  font-size: 0.7em;
}

.paginationContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 5px;
}

.page {
  padding: 3px 5px;
  border: 1px solid var(--color-border);
  border-radius: 5px;
  font-size: 0.8em;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.page:hover {
  box-shadow: 5px 5px 5px var(--color-shadow);
  transform: translateY(-2px);
}

.pages {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition: 0.2s ease-in-out;
}

.page[data-current="true"] {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: 3px;
  background: var(--color-princ-monibrand);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: default;
}

.currenPageDetail {
  font-size: 0.8em;
  opacity: 0.7;
}

.currentPage {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: 3px;
  background: var(--color-princ-monibrand);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: default;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  box-shadow: none;
  font-size: 0.9em;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  outline: 0;
  cursor: pointer;
  height: 100%;
  border: 1px solid var(--color-border);
  border-radius: 5px;
  transition: 0.2s ease-in-out;
}

.button:hover {
  border-radius: 0;
  box-shadow: inset 3px 3px 2px var(--color-shadow);
}

@media screen and (max-width: 1000px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 700px) {
  .paginationContainer .page,
  .txtPerPage {
    display: none;
  }
}
