.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}
