.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pageHeader {
  margin: 0 10px;
  display: flex;
  justify-content: flex-end;
}

.retrieve {
  background: #fff;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 15px;
  transition: 0.2s ease-in-out;
  color: var(--color-title);
  border: 1px solid var(--color-border);
  background-color: var(--color-princ-monibrand);
}

.purge {
  background: #fff;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 15px;
  transition: 0.2s ease-in-out;
  color: var(--color-title);
  border: 1px solid var(--color-border);
  background-color: var(--color-princ-monibrand);
  margin-right: 10px;
}
