.containerCountChild {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.counter {
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
}

.containerCountChild .parenthesis.counter::after {
  display: inline-block;
  content: "/";
}

.containerCountChild .parenthesis.counter:first-child::before {
  display: inline-block;
  content: "";
  color: var(--color-bg-alt);
}

.containerCountChild .parenthesis.counter:nth-last-child(1)::after {
  display: inline-block;
  content: "";
  color: var(--color-bg-alt);
}

@media screen and (max-width: 700px) {
  .containerCountChild {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
