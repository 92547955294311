.containerButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.containerButtons button {
  padding: 15px 25px;
  width: 100%;
}

.datePickerContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-shadow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  z-index: 2500 !important;
}

.datePicker {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
