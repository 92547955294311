.container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.box {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  padding: 10px;
  justify-content: space-between;
  gap: 15px;
  border-radius: 3px;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

.keywords {
  display: flex;
  position: relative;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  height: 100%;
  grid-column: 1/3;
}

.field {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  height: 100%;
}

.large {
  grid-column: 1 / span 2;
}

.field,
.keywords {
  padding: 10px;
}

.separator {
  border-top: 1px solid var(--color-border);
  grid-column: 1 / span 2;
  margin: 0 10px;
}

.label {
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.label::first-letter {
  text-transform: uppercase;
}

.fieldCheckbox {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  cursor: pointer;
  height: 100%;
  transition: 0.2s ease-in-out;
}

.fieldCheckbox label {
  cursor: pointer;
}

.checkboxText {
  font-size: 16px;
}

.info {
  color: var(--color-link-menu);
  cursor: pointer;
  font-size: 14px;
  width: 14px;
  height: 14px;
  margin: 3px;
}
