.container {
  align-self: center;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

.container > div {
  width: 100%;
}

.desc {
  padding: 10px 30px;
}

.label {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 0.8em;
}
