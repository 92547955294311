.container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  /* padding: 15px 0; */
  position: relative;
  margin-bottom: 10px;
}

.container div[class$="singleValue"] {
  font-size: 14px !important;
}

.title {
  /* opacity: 0.5; */
  font-size: 0.8em;
  color: #333;
  font-weight: 500;
}

.title::first-letter {
  text-transform: capitalize;
}

.input {
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  outline: 0;
  transition: 0.2s ease-in-out;
  border-radius: 5px;
  border: 1px solid var(--color-border);
  padding: 8px 12px;
  gap: 10px;
  background: var(--color-bg-tab);
  font-family: "Work Sans", sans-serif;
}

.input::placeholder {
  color: hsl(0deg 0% 20%);
  text-align: left;
}
