.container {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  border-radius: 5px 5px 0 0;
  border: 1px solid var(--color-border);
  padding: 8px;
  gap: 10px;
}

.input {
  width: 100%;
  font-size: 1rem;
  outline: 0;
  border: none;
  transition: 0.2s ease-in-out;
}

.input::placeholder {
  color: var(--color-bg-alt);
  font-style: italic;
  text-align: left;
  font-size: 0.9em;
}

.export {
  color: var(--color-bg-alt);
  font-size: 20px;
  line-height: 0;
  transition: 0.2s ease-in-out;
}

.export:hover {
  transform: scale(1.08);
}
