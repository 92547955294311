.th {
  background: var(--color-bg-extra-light);
  border-right: 1px solid var(--color-bg-lightgrey);
  color: rgb(0 0 0 / 50%);
  cursor: pointer;
  font-size: 1em;
  padding: 10px;
  text-align: left;
  text-transform: lowercase;
  top: 0;
  transition: 0.2s ease-in-out;
  white-space: nowrap;
  z-index: 1;
  height: 38px;
  box-shadow:
    0 0 0 #fff,
    0 1px 0 var(--color-bg-lightgrey),
    0 -1px 0 var(--color-bg-lightgrey);
}

.th.simple {
  border-right: unset;
  color: var(--color-princ-monibrand-second);
  font-size: 14px;
  font-weight: 600;
  background: #fff;
}

.th.center {
  text-align: center;
}

.checkbox {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.th:first-child {
  box-shadow:
    0 -1px 0 var(--color-bg-lightgrey),
    inset 1px 0 0 var(--color-bg-lightgrey),
    0 1px 0 var(--color-bg-lightgrey);
}

.th.simple:first-child {
  box-shadow: 0 0 0 #fff, 0 1px 0 var(--color-bg-lightgrey), 0 -1px 0 var(--color-bg-lightgrey);
}

.th:last-child {
  box-shadow:
    0 1px 0 var(--color-bg-lightgrey),
    0 -1px 0 var(--color-bg-lightgrey),
    1px -1px 0 var(--color-bg-lightgrey),
    1px 1px 0 var(--color-bg-lightgrey);
}

.th.simple:last-child {
  box-shadow:
    0 1px 0 var(--color-bg-lightgrey),
    0 -1px 0 transparent,
    1px -1px 0 transparent,
    1px 1px 0 transparent;
}

.tr {
  font-size: 0.9em;
  transition: 0.2s ease-in-out;
}

.tr:last-child() {
  border: none;
}

.thTxt[data-sortable="true"] {
  display: inline-grid;
  gap: 5px;
  grid-template-columns: repeat(2, auto);
}

.sort {
  display: inline-flex;
  flex-direction: column;
  gap: 1px;
  justify-items: center;
  position: relative;
  transition: 0.2s ease-in-out;
}

.icon {
  display: inline-flex;
  margin: 0 0 0 5px;
  position: absolute;
}

.icon[data-enabled="true"] {
  color: var(--color-princ-monibrand);
}

.thTxt::first-letter,
.title::first-letter {
  text-transform: uppercase;
}

.icon,
.sort,
.thTxt {
  justify-content: center;
}

.checkbox,
.icon,
.sort,
.thTxt {
  align-items: center;
}

.container {
  position: sticky;
  top: -2px;
  z-index: 2;
}

.container.noFixedHeader {
  position: unset;
}
