.filtersContainer {
  width: 330px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 8px 10px #00000029;
  opacity: 1;

  /* padding: 0 5px; */
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.filtersTitle {
  padding-left: 15px;
  padding-right: 10px;
  color: var(--color-title);
  font-weight: 500;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filtersToggle,
.filtersToggle:hover {
  background: none;
  padding: 0;
}

.filtersList {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
