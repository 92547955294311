.container {
  display: inline-grid;
  margin: auto;
  grid-template-columns: auto 1fr;
  gap: 5px;
  cursor: pointer;
  align-items: center;
}

.label {
  color: var(--color-txt-princ);
  font-size: 1rem;
}

.checkbox {
  border: 1px solid var(--color-border);
  width: 15px;
  height: 15px;
  position: relative;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.checkbox[data-checked]::before,
.container::after {
  content: "";
  position: absolute;
  transition: 0.1s ease-in-out;
}

.checkbox::before {
  top: 1px;
  bottom: 1px;
  left: 1px;
  transform-origin: center center;
  right: 1px;
  background: var(--color-princ-monibrand);
}

.checkbox[data-checked="true"]:hover::before {
  transform: scale(0.8);
  border-radius: 25%;
}

.checkbox[data-checked="false"]::before {
  opacity: 0;
  top: calc(50% - 1px);
  bottom: calc(50% - 1px);
  border-radius: 50%;
  transform: rotate(-45deg);
}

.checkbox[data-checked="false"]:hover::before {
  opacity: 1;
  border-radius: 0;
  top: calc(50% - 1px);
  bottom: calc(50% - 1px);
}
