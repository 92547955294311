.container {
  padding: 0 10px;
  position: relative;
  height: calc(100% - 50px);
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 20px;
  width: 100%;
}

.page {
  position: relative;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.pagination {
  bottom: 10px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 10px;
}

.num {
  font-size: 2em;
  font-weight: bold;
  cursor: pointer;
  background: var(--color-princ-monibrand);
  padding: 5px 10px;
  color: white;
  border-radius: 30px;
}

.num[data-current="true"] {
  background: rgb(223 223 223);
}

.iframe {
  overflow-y: auto;
  border: none;
  padding: 5px;
}

.desc {
  text-align: center;
  padding: 15px;
  border: 1px solid var(--color-princ-monibrand);
  border-radius: 20px;
}

.notFound {
  text-align: center;
  font-size: 3em;
}
