.tableContainer {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  width: 100%;
}

.tableContainer[data-actions="true"] {
  grid-template-rows: auto auto 1fr;
}

.tableContainerFilter {
  align-items: center;
  margin: 10px 0;
}

.tableContainerButtons {
  justify-content: flex-end;
  padding: 0 10px;
}

.tableContainerButtons button {
  font-size: 0.8em;
}

.tableBox {
  height: 100%;
}

.table {
  padding: 0 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

table.ScrollbarsCustom-Content {
  border-spacing: 0;
}

.table .ScrollbarsCustom-Wrapper {
  inset: 0 !important;
}

.table .ScrollbarsCustom-Scroller {
  margin-right: -15px !important;
}

/* stylelint-disable function-url-quotes */
@-moz-document url-prefix() {
  .table .ScrollbarsCustom-Scroller {
    margin-right: -20px !important;
  }
}

.table .ScrollbarsCustom-TrackY {
  right: -12px !important;
}

.tableCheckbox {
  align-items: center;
  flex-direction: column;
}

.tableContainer,
.table {
  box-sizing: border-box;
}

.tableAction,
.tableBox,
.tableContainer {
  position: relative;
}

.checkbox,
.tableContainerButtons {
  display: flex;
}

.tableLabel {
  font-weight: 700;
}

.tableButtonsModale {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px;
}

.tableTxtModale::first-letter {
  text-transform: uppercase;
}
