.container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: unset !important;
  align-items: unset !important;
}

.inlineContainer {
  display: flex;
  flex-direction: column;
  justify-content: unset !important;
  align-items: unset !important;
}

.label {
  font-size: 16px;
  text-align: left;
}
