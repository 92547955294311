.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.dropZone {
  border: 2px solid #ddd;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  transition: 200ms ease-in-out;
  position: relative;
  padding: 2px;
  overflow: hidden;
  cursor: pointer;
}

.dropZone[data-ishover="true"] {
  border: 2px dashed green;
}

.dropZone:hover {
  padding: 4px;
}

.avatar {
  width: 100%;
  height: auto;
  border-radius: 50%;
  overflow: hidden;
}

.input {
  display: none;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.label {
  text-align: center;
  cursor: pointer;
  color: var(--color-princ-monibrand-second);
  padding: 3px;
  border-radius: 3px;
  border: 1px solid var(--color-princ-monibrand-second);
  transition: 200ms ease-in-out;
  font-size: 0.8em;
}

.label:hover {
  background: var(--color-princ-monibrand);
  border: 1px solid var(--color-princ-monibrand);
  color: white;
}

.modale {
  display: flex;
  position: fixed;
  flex-direction: column;
  gap: 15px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 80%);
  z-index: 10;
}

.cropper {
  max-width: 400px;
}
