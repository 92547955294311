.container {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
}

.body {
  flex: 1 1 auto;
  margin: 0;
  padding-bottom: 40px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  padding-bottom: 0;
  padding-top: 50px;
  position: absolute;
  bottom: 0;
  background: transparent linear-gradient(#fff0 0%, #fffffff6 60%, #fff 100%) 0% 0% no-repeat padding-box;
  width: 100%;
  opacity: 1;
  z-index: 100;
}

.footer button {
  padding: 0;
}

.historyButton {
  background: transparent;
  font-size: 14px;
  color: var(--color-link-menu);
  text-transform: none;
}

.historyButton:hover {
  background: transparent;
  color: var(--color-princ-monibrand);
}

.history {
  height: 100%;
}
