.button {
  position: relative;
  width: 175px;
  height: 53px;
  box-sizing: border-box;
  margin: 6px 0 0 6px;
  font-size: 11px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  background-color: var(--color-princ-monibrand);
  outline: 0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.button[disabled] {
  background-color: var(--color-bg-alt);
  color: var(--color-bg-lightgrey);
}

.button:hover::before {
  display: none;
}

.border {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid var(--color-border);
  border-radius: 10px;
  transition: all 0.2s ease-out;
  transform: translate(-5px, -5px);
}

.border:hover,
.button[disabled] .border {
  transform: translate(0);
}

.arrow {
  position: absolute;
  width: 24px;
  height: 9px;
  top: calc(50% - 4.5px);
  right: -15px;
}
