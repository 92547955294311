.container {
  display: inline-grid;
  position: relative;
  grid-template-columns: 1fr auto;
  justify-content: center;
  grid-column-gap: 10px;
}

.msg {
  opacity: 0;
  background: var(--color-green);
  color: #fff;
  padding: 3px;
  font-size: 0.9em;
  margin: 5px 0;
  position: absolute;
  top: -30px;
  white-space: nowrap;
  animation: bounce-in 1s 2 alternate;
  box-shadow: 3px 3px 3px var(--color-shadow);
  z-index: 3;
}

.icon {
  cursor: pointer;
  transition: 0.2s ease-in-out;
  color: var(--color-princ-monibrand);
}

.icon:hover {
  transform: rotate(-5deg) scale(1.1);
}

.input {
  border: 1px solid var(--color-border);
  padding: 3px;
  z-index: 1;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    border-radius: 50%;
    transform: translateY(20px);
  }

  25% {
    opacity: 1;
    border-radius: 5px;
    transform: translateY(-5px);
  }

  50% {
    opacity: 1;
    border-radius: 5px;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    border-radius: 5px;
    transform: translateY(0);
  }
}
