.container {
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  width: 800px;
  max-height: 500px;
  grid-gap: 30px;
  gap: 30px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.checkOptions {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}

.checkTitle {
  color: black;
  font-weight: bold;
  width: 100%;
  font-size: 1rem;
  text-align: left;
}

.checkbox {
  padding-left: 15px;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding-bottom: 40px;
}
