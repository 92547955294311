.errorsList {
  color: var(--color-txt-error);
  font-size: 12px;
}

.errors {
  color: var(--color-txt-error);
  font-size: 12px;
  white-space: pre-wrap;
}
