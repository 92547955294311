.container {
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  width: 800px;
  max-height: 500px;
  grid-gap: 30px;
  gap: 30px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
