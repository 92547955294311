.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  margin: 20px;
  box-sizing: border-box;
  gap: 10px;
}

.containerTwoFields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.input {
  outline: none;
  border: 1px solid var(--color-bg-alt);
  font-size: 1em;
  border-radius: 5px;
  box-shadow: inset 3px 3px 3px rgb(0 0 0 / 10%);
  transition: 200ms ease-in-out;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0;
}

.label {
  color: var(--color-bg-alt);
  width: 100%;
  cursor: pointer;
}

.separate {
  border-top: 1px dashed var(--color-bg-alt);
  margin: 10px 0;
}

.containerButtons {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

@media screen and (max-width: 750px) {
  .containerTwoFields {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .container {
    width: 100%;
  }

  .form {
    width: calc(100% - 40%);
  }
}
