.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 55px;
  box-shadow: 0 3px 6px #00000029;
  z-index: 100;
}

.left {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 18px;
}

.right {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 15px;
}

.back {
  color: #768d8c;
  font-size: 24px;
}

.statusNotWaiting {
  display: none;
}

.statusWaiting {
  display: inline-block;
  margin-left: 3px;
}

.back:hover {
  color: var(--color-princ-monibrand);
}

.label {
  font-size: 14px;
  color: #333;
}

.labelWrapper {
  cursor: pointer;
}

.externalLink {
  color: var(--color-link-menu);
}

.externalLink:hover {
  color: var(--color-princ-monibrand);
}

.labelActions {
  display: none;
  justify-content: center;
}

.labelWrapper:hover .label {
  visibility: hidden;
  height: 0;
}

.labelWrapper:hover .labelActions {
  display: flex;
}

.labelAction {
  margin: 0 8px;
  cursor: pointer;
}

.labelAction svg {
  cursor: pointer;
}

.labelAction:hover path {
  fill: var(--color-princ-monibrand);
}

.labelName {
  font-size: 20px;
  font-weight: 500;
}

.actionFlagIcon {
  width: 26.6px !important;
  line-height: unset !important;
  height: 20px !important;
}

.actionEngineIcon {
  width: 22px;
  height: 22px;
}

.dateText {
  font-size: 16px;
  font-weight: 500;
}

.labelWithIcon {
  display: flex;
  gap: 5px;
  align-items: center;
}

.labelWithIcon .icon {
  display: flex;
  align-items: center;
  height: 100%;
}

.firstDate {
  margin-right: 10px;
}

.status {
  border-radius: 50px;
  opacity: 1;
  background-color: var(--color-princ-monibrand);
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  padding: 10px 20px;
}

.statusNew {
  background-color: var(--color-azure);
}

.statusReappearance {
  background: var(--color-purple);
}

.statusIgnored {
  background: var(--color-txt-error);
}

.statusAccepted {
  background: var(--color-green);
}

.statusRefused {
  background: var(--color-red);
}

.statusDisappeared {
  background: #035607;
}

.statusRequested {
  background: #0a009b;
}

.statusInprogress {
  background: var(--color-princ-monibrand);
}

.statusReminded {
  background: var(--color-orange);
}

.statusBlocked {
  background: var(--color-bg-alt);
}

.intentSuccess {
  background: var(--color-green);
}
