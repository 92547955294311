.container {
  display: flex;

  /* margin-top: 10px; */
}

.container > div {
  padding: 10px;
}

.container > div:not(.container > div:nth-child(1n +4)) {
  background: var(--color-bg-extra-light);
}

.counters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  min-height: 48px;
}

.description {
  display: flex;
  flex-grow: 1;
  min-height: 48px;
}

@media screen and (max-width: 700px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }
}
