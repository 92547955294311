.termsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-yellow-border);
  padding: 5px;
}

.terms {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  text-align: center;
}

.term {
  font-size: 0.8em;
}
